import { Box, Typography, Button } from '@mui/material';
import Image from 'next/image';
import Router from 'next/router';

import { Routes } from 'config';

const PageNotFound = () => (
  <Box
    sx={(theme) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundColor: theme.palette.greyBackground.primary,
    })}
  >
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '378px',
        width: '320px',
        padding: '40px 24px 24px',
        backgroundColor: theme.palette.whiteBackground.primary,
      })}
    >
      <Image src="/raven.png" alt="raven on skull" width={128} height={128} />
      <Typography variant="h4" sx={{ mt: '24px' }}>
        Ooooops!
      </Typography>
      <Typography sx={{ mt: '24px' }}>This page doesn&apos;t exist anymore.</Typography>
      <Button variant="contained" color="primary" onClick={() => Router.replace(Routes.dashboard)} sx={{ mt: '24px' }}>
        Come back home, darling
      </Button>
    </Box>
  </Box>
);

export default PageNotFound;
